<template>
  <section class="title-image-card">
    <ul class="card-carousel" :class="scrolls" :style="{ 'grid-auto-columns': gridAutoColumns }">
      <li v-for="(card, index) in block.data" :key="'title-image-card-' + index">
        <figure class="card">
          <div class="card-image">
            <router-link :to="card.path">
              <img
                v-if="card.img"
                :src="card.img.src"
                :srcset="card.img.srcset"
                :sizes="card.img.sizes"
                :alt="card.img.alt"
                :title="card.img.title"
                :width="card.img.width"
                :height="card.img.height"
              />
            </router-link>
          </div>
          <figcaption class="card-header">
            <div class="card-title">{{ card.title }}</div>
            <div v-if="card.subtitle" class="card-subtitle">{{ card.subtitle }}</div>
          </figcaption>
        </figure>
      </li>
    </ul>
  </section>
</template>

<style>
.title-image-card {
  overflow: auto;
}
.title-image-card .card {
  position: relative;
  display: inline-block;
}
.title-image-card .card-image img {
  display: block;
  max-width: 100%;
  height: auto;
}
.title-image-card .card-carousel {
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x proximity;
  margin: 0;
}
.title-image-card .card-carousel > li {
  display: flex;
  flex-direction: column;
  scroll-snap-align: center;
}
</style>

<script>
// TODO Figure out why mixin breaks this
// import cardSlider from '../mixins/cardSlider';
export default {
  // mixins: [cardSlider],
  name: 'BlockUriTitleImageCardSlider',
  props: {
    block: Object,
  },
  computed: {
    gridAutoColumns() {
      if (this.block.data.length == this.block.properties.cards) {
        return (100 - (parseInt(this.block.data.length) - 1)) / this.block.properties.cards + '%'; // Make Full Width
      } else {
        return (100 - (parseInt(this.block.properties.cards) + 2)) / this.block.properties.cards + '%'; // Show A Little Offset
      }
    },
    scrolls() {
      let scrolls = {};
      scrolls.scroll = this.block.data.length > this.block.properties.cards ? true : false;
      scrolls['no-scroll'] = this.block.data.length == this.block.properties.cards ? true : false;
      scrolls['single'] = this.block.data.length == 1 ? true : false;
      return scrolls;
    },
  },
  data() {
    return {};
  },
};
</script>
